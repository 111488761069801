import * as React from "react"
import Layout from "../components/layout";
import Social from "../components/social";
import {Link} from "gatsby";
import jendrik from "../images/jendrik-closeup.jpg";

const AboutPage = () => {
  return (
    <Layout isBlurred title="About">
      <h1 className="text-7xl">About</h1>
      <img src={jendrik} alt={"Jendrik"} width={'auto'} height={'300px'} className="h-80"/>
      <p className="text-xl mb-6 mt-4">
        Jendrik is a German singer and musical performer who will represent Germany at the Eurovision Song Contest 2021 in Rotterdam, Netherlands with his song "I Don't Feel Hate"</p>
      <Social/>
      <div className="flex flex-row justify-center mt-6">
        <Link to="/">
          <button className="btn mr-2">Home</button>
        </Link>
        <Link to="/contact">
          <button className="btn">Contact</button>
        </Link>
      </div>
    </Layout>
  )
}

export default AboutPage
